.formWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  @media (max-width: 1025px) {
    justify-content: flex-start;
  }
}

.field {
  & input {
    height: 40px;
  }
}

.button {
  max-width: 100px;
  min-height: 40px;
  margin-left: 20px;
}

.doneText {
  text-align: right;
}
