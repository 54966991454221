.wrapper {
  background-color: white;
  composes: responsivenessClass from global;
  padding: 40px var(--desktopSidePaddingSmall);
  border-top: 1px solid #e5e5e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  max-width: 370px;
}

.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }

  @media (max-width: 1025px) {
    max-width: 100px;
  }
}

.link {
  color: var(--colorGrey700);
  margin-left: 20px;
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (max-width: 1025px) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.sectionTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.sectionTopLeft {
  width: 50%;

  @media (max-width: 1025px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

.sectionTopRight {
  width: 50%;
  @media (max-width: 1025px) {
    margin-top: 20px;
    width: 100%;
  }
}

.line {
  width: calc(100% - 80px);
  height: 1px;
  background-color: #e5e5e6;
  @media (max-width: 1025px) {
    width: 100%;
  }
}

.sectionBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px;
  @media (max-width: 1025px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 0;
  }
}

.facebook {
  margin-right: 20px;
}

.socialMobile {
  display: none;
  @media (max-width: 1025px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
}

.bottomRight {
  @media (max-width: 1025px) {
    display: none;
  }
}

.bottomLeft {
  @media (max-width: 1025px) {
    width: 100%;
    margin-top: 40px;
  }
}

.bottomCenter {
  @media (max-width: 1025px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
