.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 40px var(--desktopSidePaddingSmall);

  @media (min-width: 1025px) {
    padding: 40px var(--desktopSidePaddingSmall);
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
}

.cover {
  object-fit: cover;
  height: 200px;
  width: 35vw;
  border-radius: 20px;

  @media (max-width: 1025px) {
    width: 100%;
    height: auto;
  }
}

.content {
  color: black;
  & h2 {
    margin: 0;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }

  & p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
  }
}

.arrow {
  height: 30px;
  width: auto;
}

.cta {
  font-weight: 700;
}
